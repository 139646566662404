import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import CheckboxFormControl from "components/CheckboxFormControl";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import React, { useCallback, useContext } from "react";
import { focusJOCols, JobOrderTransferDialogV2Context } from "../../JobOrderTransferDialogV2";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import { alertError, alertSuccess } from "components/Alert";
import { blueGrey } from "@mui/material/colors";
import { SaveRounded } from "@mui/icons-material";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";


const JOItem = () => {

  const { ax } = useContext(GlobalStateContext)
  const { joItem, setJOItem, oJOItem, selectedRow, isJOChanged, onFinishToJobOrderTransfer }
    = useContext(JobOrderTransferDialogV2Context)

  const datePickerJobOrderProp = useCallback((name) => ({
    label: jobOrderColNm[name],
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: joItem[name],
    onChange: (newValue) => { setJOItem(oldState => ({ ...oldState, [name]: newValue && newValue.format("YYYY-MM-DD HH:mm:00.00") })) },
    renderInput: (params) => <TextField size="small"  {...params} fullWidth />,
  }), [joItem, setJOItem])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    label: jobOrderColNm[name],
    value: joItem[name],
    onChange: (e) => setJOItem(o => ({ ...o, [name]: e.target.value })),
    onBlur: (e) => {
      if (["ContNo", "SealNo"].includes(name)) {
        setJOItem(o => ({ ...o, [name]: e.target.value.toUpperCase() }))
      }
    }
  }), [joItem, setJOItem])

  const handleCheckBoxChange = useCallback((e) => {
    // console.log("e.target.check::",e.target.checked)
    if (!selectedRow) {
      alertError("กรุณาเลือกใบงานก่อน")
      return
    }
    if (e.target.checked) {
      setJOItem(o => ({
        ...o,
        DrvId: oJOItem.DrvId,
        TukId: oJOItem.TukId,
        DrvActTm: oJOItem.DrvActTm
      }))
    } else {
      setJOItem(o => ({ ...o, DrvId: null, TukId: null, DrvActTm: null }))
    }
  }, [oJOItem, selectedRow, setJOItem])

  const handleSave = useCallback((withFinDte) => () => {
    if (!isJOChanged && !withFinDte) return

    const colValues = {}
    for (const col of focusJOCols) {
      if (joItem[col] !== oJOItem[col]) {
        colValues[col] = joItem[col]
      }
    }
    if (withFinDte) {
      colValues.FinDte = dayjs().format("YYYY-MM-DD HH:mm:00.00")
    }
    const postData = {
      JobOrdIds: [joItem.JobOrdId],
      ColValues: colValues,
      WithFamily: 1,
      LogRmk: "แก้ไขจากหน้า รับ-จ่ายใบงาน",
      getFn: "getJobOrderByDriverV2",
      getArgs: { DrvId: joItem.DrvId }
    }
    ax.post(jobOrderApiNm.updateJobOrderColumn, postData).then(value => {
      if (value.data) {
        alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
        onFinishToJobOrderTransfer(value.data)
      }
    })
  }, [ax, onFinishToJobOrderTransfer, isJOChanged, joItem, oJOItem])


  return (
    <BoxFC flex={1} height="100%" sx={{ gap: 1, bgcolor: blueGrey[50], p: 1, borderRadius: 2 }} >
      <BoxFR>
        <Typography variant="h6">ข้อมูลใบงาน</Typography>
        <Box flex={1} />
        {/* <CheckboxFormControl checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} label="ใช้ตัวพิมพ์ใหญ่" /> */}
      </BoxFR>
      {joItem.JobOrdId ?
        <BoxFC sx={{ gap: 1 }}>
          <BoxFR>
            <Box flex={1}>
              <DateTimePickerCustomTime {...datePickerJobOrderProp("FinDte")} />
            </Box>
            <Box flex={1}>
              <CheckboxFormControl label="รับผิดชอบใบงาน" checked={joItem.DrvId !== null} onChange={handleCheckBoxChange} />
            </Box>
          </BoxFR>
          <BoxFC sx={{ gap: 1 }}>
            <BoxFR>
              <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeArvTm")} />
              <DateTimePickerCustomTime {...datePickerJobOrderProp("TakeLevTm")} />
            </BoxFR>
            <BoxFR>
              <DateTimePickerCustomTime {...datePickerJobOrderProp("ArvTm")} />
              <DateTimePickerCustomTime {...datePickerJobOrderProp("LevTm")} />
            </BoxFR>
            <BoxFR>
              <TextField {...textFieldProps("ContNo")} />
              <TextField {...textFieldProps("SealNo")} />
            </BoxFR>
            <BoxFR>
              <TextField {...textFieldProps("FullWt")} />
              <TextField {...textFieldProps("TareWt")} />
            </BoxFR>
            <TextField {...textFieldProps("Rmk")} multiline rows={2} />
          </BoxFC>
          <BoxFR>
            <Button fullWidth variant="contained" size="small" color="secondary" onClick={handleSave(true)}>
              <SaveRounded sx={{ mr: 1 }} />ลงเวลาจบและบันทึก</Button>
            <Button fullWidth variant="contained" size="small" onClick={handleSave(false)}>
              <SaveRounded sx={{ mr: 1 }} />บันทึก</Button>
          </BoxFR>

        </BoxFC>
        : <Typography variant="body1">กรุณาเลือกใบงาน</Typography>}

    </BoxFC>
  );
}

export default React.memo(JOItem);