import { Box, Button, Dialog, DialogContent, Divider, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { pettyCashApiNm, pettyCashColNm } from "./constant"
import { pcItemAddTableColumns } from "./columns"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { bahtProps } from "utils/bahtProps"
import { blueGrey, green, red } from "@mui/material/colors"
import { AddCircleRounded } from "@mui/icons-material"
import ComboBox from "components/ComboBox"
import { numberFormat } from "utils"
import dayjs from "dayjs"
import { UserContext } from "contexts/UserContext"

const AddItemTableDialog = ({ pcTrnsData, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)
  const [dataTable, setDataTable] = useState([])
  const [expId, setExpId] = useState(null)

  const textFieldProps = useCallback((name) => ({
    size: 'small',
    value: pcTrnsData[name],
    label: pettyCashColNm[name]
  }), [pcTrnsData])

  const modDataTable = useMemo(() => {
    // let sum = 0;
    // for (const row of dataTable) {
    //   sum += row.PayAmnt
    // }
    // return [...dataTable, {
    //   id: 0,
    //   ExpNm: "รวม",
    //   PayAmnt: sum
    // }]
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["Amnt", "PayAmnt"],
      sumColTotal: "ExpNm"
    })
  }, [dataTable])

  const top10Exp = useMemo(() => {
    return msData.expOprCombo.slice(0, 10)
  }, [msData.expOprCombo])

  const next10Exp = useMemo(() => {
    return msData.expOprCombo.slice(10)
  }, [msData.expOprCombo])

  const diffAmnt = useMemo(() => {
    return +pcTrnsData.PayAmnt - dataTable.reduce((acc, row) => {
      const { Amnt, VatPrct, WhtPrct } = row
      return acc + (((Amnt / (1 + (VatPrct / 100))) * (1 - (WhtPrct / 100)))
        + (((Amnt / (1 + (VatPrct / 100))) * VatPrct) / 100))
    }, 0)

  }, [pcTrnsData.PayAmnt, dataTable])

  const handleEditCommit = useCallback((params) => {
    console.log("params", params)
    setDataTable(o => {
      const result = [...o]
      const foundIndex = result.findIndex(r => r.id === params.id)
      result[foundIndex][params.field] = params.value
      const { Amnt, VatPrct, WhtPrct } = result[foundIndex]
      result[foundIndex] = {
        ...result[foundIndex], [params.field]: params.value,
        PayAmnt: (((Amnt / (1 + (VatPrct / 100))) * (1 - (WhtPrct / 100)))
          + (((Amnt / (1 + (VatPrct / 100))) * VatPrct) / 100))
      }
      // const rowId0 = result.find(row => row.id === 0)
      // if(row)
      // rowId0.PayAmnt = result.reduce((acc, row) => acc + +row.PayAmnt, 0)
      return result
    })
  }, [])

  const handleAddExp = useCallback((expObj) => {
    console.log("expObj", expObj)
    setDataTable(o => {
      const result = [...o]
      const found = msData.expensePOIs.find(exp => (!exp.ExpId || exp.ExpId === expObj.id)
        && (!exp.JobId || exp.JobId === pcTrnsData.JobId)
        && (!exp.POIId || [pcTrnsData.TakePOIId, pcTrnsData.LocPOIId, pcTrnsData.RtnPOIId].includes(exp.POIId))
        && (!exp.ContSize || exp.ContSize === pcTrnsData.ContSize)
        && (!exp.ContType || exp.ContType === pcTrnsData.ContType)
        && (!exp.ShpmTypId || exp.ShpmTypId === pcTrnsData.ShpmTypId)
        && (!exp.TlrTypId || exp.TlrTypId === pcTrnsData.TlrTypId)
      )
      console.log("found", found)
      const amnt = +(found?.UntPr || 0)
      const vatPrct = +(found?.VatPrct || 0)
      const whtPrct = +(found?.WhtPrct || 0)
      const newRow = {
        id: result.length + 1,
        No: result.length + 1,
        IsCost: found?.IsCost || 0,
        IsOwnRcpt: found?.IsOwnRcpt || 0,
        ExpId: expObj.id,
        ExpNm: expObj.label,
        Dscp: "",
        DocNo: "",
        Rmk: "",
        DocDte: null,
        Amnt: amnt,
        VatPrct: vatPrct,
        WhtPrct: whtPrct,
        PayAmnt: (((amnt / (1 + (vatPrct / 100))) * (1 - (whtPrct / 100)))
          + (((amnt / (1 + (vatPrct / 100))) * vatPrct) / 100))
      }
      result.push(newRow)
      return result
    })
  }, [msData.expensePOIs, pcTrnsData])

  const handleAddFromCombo = useCallback(() => {
    if (expId) {
      const expObj = msData.expOprCombo.find(exp => exp.id === expId)
      handleAddExp(expObj)
    }
  }, [msData.expOprCombo, expId, handleAddExp])

  const handleRemoveRow = useCallback((row) => {
    setDataTable(o => o.filter(r => r.id !== row.id))
  }, [])

  const handleChangeIsCost = useCallback((id, colName) => {

    setDataTable(o => {
      const result = [...o]
      const foundIndex = result.findIndex(r => r.id === id)
      result[foundIndex] = { ...result[foundIndex], [colName]: result[foundIndex][colName] === 0 ? 1 : 0 }
      return result
    })
  }, [])


  const handleDialogOk = useCallback(() => {
    const postData = {
      argsArr: dataTable.map(row => ({
        ...row,
        PCTrnsId: pcTrnsData.PCTrnsId,
        PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        JobOrdId: pcTrnsData.JobOrdId,
        AdmAccId: user.AccId,
      })),
      getFn: "getPettyCashDetail",
      PCTrnsId: pcTrnsData.PCTrnsId,
    }
    ax.post(pettyCashApiNm.insertPCItemBulk, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })
  }, [ax, dataTable, pcTrnsData, user.AccId, onFinish, setDialogOpen])

  const pcItemAddTableColumnsMemo = useMemo(() => {
    return pcItemAddTableColumns(handleChangeIsCost, handleRemoveRow)
  }, [handleChangeIsCost, handleRemoveRow])

  useEffect(() => {
    if (!dialogOpen) {
      setDataTable([])
      setExpId(null)
    }
  }, [dialogOpen])

  console.log("pctrnsData", pcTrnsData)
  console.log("top10Exp", top10Exp)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'
      PaperProps={{
        style: {
          width: 1300,
          minWidth: 1300,
          maxWidth: 1300,
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"เพิ่มการใช้เงิน"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} sx={{ gap: 1 }} >
          <BoxFR>
            <Typography variant="h6">เงินจ่าย :</Typography>
            <TextField {...textFieldProps('UsrNNm')} sx={{ width: 150 }} />
            <TextField {...textFieldProps('ExpNm')} />
            <TextField {...textFieldProps('Loc')}
              value={`${pcTrnsData.TakePlc || ""}-${pcTrnsData.Loc || ""}-${pcTrnsData.RtnPlc || ""}`} sx={{ flex: 1 }} />
            <TextField {...textFieldProps('PayAmnt')} {...bahtProps} sx={{ width: 150 }} />
            <TextField {...textFieldProps('DiffAmnt')} label="คงเหลือ" value={numberFormat(diffAmnt)} {...bahtProps} sx={{ width: 150 }} />
          </BoxFR>
          <Divider />
          <BoxFR>

          </BoxFR>
          <BoxFR>
            <BoxFC flex={1}>
              <BoxFR>
                <Typography variant="h6">การใช้เงิน</Typography>
                <ComboBox sx={{ width: 220 }} options={next10Exp} label={"ค่าใช้จ่าย"}
                  selectedId={expId}
                  setSelectedId={(id) => setExpId(id)}
                />
                <Button variant="contained" onClick={handleAddFromCombo}><AddCircleRounded sx={{ mr: 1 }} /> เพิ่ม</Button>
              </BoxFR>
              <Box height={300} sx={{
                ...sxSumColorClass,
                "& .cost": { bgcolor: red[50] },
                "& .income": { bgcolor: green[50] }
              }}>
                <DataGridCellExpand
                  hideFooter
                  hideToolbar
                  rows={modDataTable}
                  columns={pcItemAddTableColumnsMemo}
                  onCellEditCommit={handleEditCommit}
                  getRowClassName={getSumColorClassName}
                  experimentalFeatures={{ rowPinning: true }}
                  pinnedRows={{ bottom: [modDataTable.find(row => row.id === 0)] }}
                />
              </Box>
            </BoxFC>
            <BoxFC sx={{ gap: 0 }}>
              <Box p={0.5} width={200} bgcolor={blueGrey[50]} height={335} sx={{ overflow: "auto" }}>
                {top10Exp.map(exp => (
                  <Button key={exp.id} sx={{ p: 0, justifyContent: "flex-start" }} onClick={() => { handleAddExp(exp) }}>
                    <AddCircleRounded sx={{ mr: 1 }} />
                    <Box sx={{ width: 150, py: 0.5, textAlign: "left", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                      {exp.label}
                    </Box>
                  </Button>
                ))}
              </Box>
              <Typography variant="caption">(ค่าใช้จ่าย Top 10 เรียงตาม Priority)</Typography>
            </BoxFC>
          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(AddItemTableDialog)