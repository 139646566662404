import { Box, Button, ButtonGroup, Dialog, DialogContent, Divider, Paper, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { pcItemColumns, pcTransColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import AddAdvanceDialog from './AddAdvanceDialog';
import ReimbursementDialog from './ReimbursementDialog';
import ChangeDialog from "./ChangeDialog"
import { alertConfirmDelete, alertError } from "components/Alert"
import { addIdForDataGrid, numberFormat } from "utils"
import { pettyCashApiNm } from "./constant"
import AddItemDialog from "./AddItemDialog"
import { green, red } from "@mui/material/colors"
import dayjs from "dayjs"
import CheckboxFormControl from "components/CheckboxFormControl"
import { initAdvanceDialogData } from "./initData"
import { defaultCashChangePCJnlId, defaultExpWithDrawId } from "branch/constant/defaultId"
import ComboBox from "components/ComboBox"
import PopoverBarebone from "components/PopoverBarebone"
import { ArrowDropDownRounded, ViewListRounded } from "@mui/icons-material"
import { UserContext } from "contexts/UserContext"
import AddItemTableDialog from "./AddItemTableDialog"

let mPCTrnsId = null
let dialogPCTrnsId = null
let hasChange = false
let pcItmId = null
let initChangeDialogData = null

const PettyCashDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, lastFilter, getFn, getArgs }) => {
  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [pcItemDataTable, setPCItemDataTable] = useState([])
  const [pcTrnsDataTable, setPCTrnsDataTable] = useState([])
  const [dialogAddAdvanceOpen, setDialogAddAdvanceOpen] = useState(false)
  const [dialogReimbursementOpen, setDialogReimbursementOpen] = useState(false)
  const [dialogChangeOpen, setDialogChangeOpen] = useState(false)
  const [dialogAddItemTableOpen, setDialogAddItemTableOpen] = useState(false)
  const [prPCTrnsData, setPrPCTrnsData] = useState({ ...initAdvanceDialogData })
  // const [dialogTransOpen, setDialogTransOpen] = useState(false)
  const [dialogItemOpen, setDialogItemOpen] = useState(false)
  const [cashChangePCJnalId, setCashChangePCJnalId] = useState(defaultCashChangePCJnlId)

  const cashChangeButtonRef = useRef(null)

  const trnsAmnt = useMemo(() => pcTrnsDataTable.reduce((sum, item) => sum + (+item.PayAmnt * (item.InOutTyp === "O" ? 1 : -1)), 0), [pcTrnsDataTable])
  const itemAmnt = useMemo(() => pcItemDataTable.reduce((sum, item) => sum + +item.PayAmnt, 0), [pcItemDataTable])
  const diffAmount = useMemo(() => {
    const found = pcTrnsDataTable.find(row => !row.PrPCTrnsId)
    if (!found) return 0
    if (found.InOutTyp === "O") {
      return +((trnsAmnt - itemAmnt).toFixed(2))
    } else {
      return +(trnsAmnt + itemAmnt).toFixed(2)
    }
  }, [trnsAmnt, itemAmnt, pcTrnsDataTable])

  const diffAmntText = useMemo(() => diffAmount === 0 ? "(-)" : diffAmount < 0 ? "(เงินเพิ่ม)" : "(เงินทอน)", [diffAmount])

  const canDelete = useMemo(() => {
    const foundPr = pcTrnsDataTable.find(row => !row.PrPCTrnsId)
    return foundPr?.PCPrdId ? false : true
  }, [pcTrnsDataTable])

  const setAllData = useCallback((data) => {
    const { pcTransactions, pcItems } = data
    const found = pcTransactions.find(item => item.PrPCTrnsId === null)
    if (!found) {
      alertError("เกิดข้อผิดพลาด ไม่พบการจ่ายเงินหลัก")
      setDialogOpen(false)
      return
    }
    mPCTrnsId = found.PCTrnsId
    setPrPCTrnsData(found ? { ...found } : { ...initAdvanceDialogData })
    setPCTrnsDataTable(addIdForDataGrid(pcTransactions, "PCTrnsId"))
    setPCItemDataTable(addIdForDataGrid(pcItems, "PCItmId"))
  }, [setDialogOpen])

  const handleAddAdvance = useCallback(() => {
    dialogPCTrnsId = null
    setDialogAddAdvanceOpen(true)
  }, [])

  const handleAddReimbursement = useCallback(() => {
    dialogPCTrnsId = null
    setDialogReimbursementOpen(true)
  }, [])

  const handleAddChange = useCallback(() => {
    dialogPCTrnsId = null
    if (!prPCTrnsData) {
      alertError("ไม่มีข้อมูลการจ่ายเงินหลัก ไม่สามารถเพิ่มเงินทอนได้")
      return
    }
    initChangeDialogData = {
      ...prPCTrnsData,
      PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      InOutTyp: diffAmount > 0 ? "I" : "O",
      Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
      Amnt: Math.abs(diffAmount),
      PrPCTrnsId: prPCTrnsData.PCTrnsId,
    }


    // ...row,
    // PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    // InOutTyp: diffAmount > 0 ? "I" : "O",
    // Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
    // Amnt: Math.abs(diffAmount),
    // PrPCTrnsId: row.PCTrnsId,
    setDialogChangeOpen(true)
  }, [prPCTrnsData, diffAmount])

  // const handleAddNewChange = useCallback(() => {
  //   dialogPCTrnsId = null
  //   handleAddChange(true)
  // }, [handleAddChange])

  const handleAddPCItem = useCallback(() => {
    pcItmId = null
    if (!prPCTrnsData) {
      alertError("ไม่มีข้อมูลการจ่ายเงินหลัก ไม่สามารถเพิ่มเงินทอนได้")
      return
    }
    setDialogItemOpen(true)
  }, [prPCTrnsData])

  const handleAddItemUseAll = useCallback(() => {
    if (pcTrnsDataTable.length === 0) return
    const pcTrns = pcTrnsDataTable[0]
    const postData = {
      PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      ExpId: pcTrns.ExpId,
      Amnt: pcTrns.Amnt,
      JobOrdId: pcTrns.JobOrdId,
      AdmAccId: user.AccId,
      IsCost: pcTrns.IsCost,
      PCTrnsId: pcTrns.PCTrnsId,
      getFn: "getPettyCashDetail",
      getArgs: { PCTrnsId: selectedId }
    }
    ax.post(pettyCashApiNm.insertPCItem, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [pcTrnsDataTable, ax, user, selectedId, setAllData])

  const onDialogFinish = useCallback((data) => {
    console.log("in PettyCahsDialog onDialogFinish data:: ", data)
    if (data) {
      hasChange = true
      setAllData(data)
    }
  }, [setAllData])

  const handleDeleteTransaction = useCallback(() => {
    const postData = {
      PCTrnsId: mPCTrnsId,
      getFn: getFn || "getPettyCashTable",
      getArgs: getArgs || lastFilter,
    }
    alertConfirmDelete("หากมีข้อมูลการทอน หรือข้อมูลข้อมูลใบเสร็จ จะถูกลบด้วย ต้องการลบข้อมูลหรือไม่?", () => {
      ax.post(pettyCashApiNm.deletePCTransaction, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [ax, onFinish, lastFilter, setDialogOpen, getFn, getArgs])


  // const pcItemColumnsMemo = useMemo(() => pcItemColumns({handleEditItmRow, handleRemoveItemRow})
  //   , [handleRemoveItemRow, handleEditItmRow])

  const openItmDialog = useCallback((params) => {
    if (params.row.PrIsCmpt) {
      // alertError("ไม่สามารถแก้ไขข้อมูลที่เสร็จสิ้นสมบูรณืแล้ว")
      return
    }
    if (params.field === "ShowDetail" || !params.field) {
      pcItmId = params.id
      setDialogItemOpen(true)
    }
  }, [])

  const openTrnDialog = useCallback((params) => {
    if (params.row.PrIsCmpt || params.row.PCPrdId) {
      // alertError("ไม่สามารถแก้ไขข้อมูลที่เสร็จสิ้นสมบูรณืแล้ว")
      return
    }
    if (params.field === "ShowDetail" || !params.field) {
      dialogPCTrnsId = params.id
      if (params.row.PrPCTrnsId) {
        initChangeDialogData = null
        setDialogChangeOpen(true)
      } else {
        setDialogAddAdvanceOpen(true)
      }
    }
  }, [])

  const handleAutoAddChange = useCallback((isWithWithdraw) => () => {
    const found = pcTrnsDataTable.find(item => item.PrPCTrnsId === null)
    if (!found) {
      alertError("ไม่มีข้อมูลการจ่ายเงินหลัก ไม่สามารถเพิ่มเงินทอนได้")
      return
    }

    // const pcJnlId = msData.pcJournalCombo.find(item => item.IsDf)?.id
    // if (!pcJnlId) {
    //   alertError("ไม่มีข้อมูลบัญชีเงินสดเริ่มต้น กรุณากดหนดใน ต้งค่าส่วนตัว>ตั้งค่าทั่วไปก่อน")
    //   return
    // }

    // initChangeDialogData = {
    //   ...found,
    //   PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //   InOutTyp: diffAmount > 0 ? "I" : "O",
    //   Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
    //   Amnt: Math.abs(diffAmount),
    //   PrPCTrnsId: mPCTrnsId,
    // }
    // setDialogChangeOpen(true)
    if (!isWithWithdraw) {
      const postData = {
        ...found,
        PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        InOutTyp: diffAmount > 0 ? "I" : "O",
        Dscp: diffAmount > 0 ? "เงินทอน" : "เงินเพิ่ม",
        Amnt: Math.abs(diffAmount),
        PCJnlId: cashChangePCJnalId,
        PrPCTrnsId: mPCTrnsId,
      }
      postData.getFn = getFn || "getPettyCashTable"
      postData.getArgs = getArgs || lastFilter
      ax.post(pettyCashApiNm.insertPCTransaction, postData).then(value => {
        if (value.data) {

          onFinish(value.data)
          setDialogOpen(false)
          // if (isWithWithdraw) {
          //   postData.ExpId = defaultExpWithDrawId
          //   postData.PrPCTrnsId = null
          //   postData.Dscp = "เงินทอนลงเบิก"
          //   postData.InOutTyp = "O"
          //   const reimbursePostData = {
          //     transactionArgs: {
          //       ...postData,
          //     },
          //     itemArgs: {
          //       ...postData,
          //       PCItmTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          //     }
          //   }

          //   reimbursePostData.getFn = getFn || "getPettyCashTable"
          //   reimbursePostData.getArgs = getArgs || lastFilter
          //   ax.post(pettyCashApiNm.insertReimbursment, reimbursePostData).then(value => {
          //     if (value.data) {
          //       onFinish(value.data)
          //       setDialogOpen(false)
          //     }
          //   })
          // } else {
          //   onFinish(value.data)
          //   setDialogOpen(false)
          // }
        }
      })
    } else {
      const postData = {
        PCItmTm: dayjs().format("YYYY-MM-DD HH:mm"), //Coz PayComponent use PayTm for PCItmTm
        ExpId: defaultExpWithDrawId,
        Dscp: "เงินทอนลงเบิก",
        DocNo: "",
        DocDte: null,
        Amnt: Math.abs(diffAmount),
        WhtPrct: 0,
        VatPrct: 0,
        IsOwnRcpt: 1,
        JobOrdId: found.JobOrdId,
        PCTrnsId: found.PCTrnsId,
        AdmAccId: user.AccId,
        IsCost: 1,
        getFn: getFn || "getPettyCashTable",
        getArgs: getArgs || lastFilter
      }
      ax.post(pettyCashApiNm.insertPCItem, postData).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    }
  }, [pcTrnsDataTable, diffAmount, ax, onFinish, lastFilter, setDialogOpen, cashChangePCJnalId, getFn, getArgs, user.AccId])

  const handleIsCmptChange = useCallback((e) => {
    const postData = {
      PCTrnsId: mPCTrnsId,
      IsCmpt: e.target.checked ? 1 : 0,
      getFn: "getPettyCashDetail",
    }
    ax.post(pettyCashApiNm.updateIsCmpt, postData).then(value => {
      if (value.data) {
        setPrPCTrnsData(o => ({ ...o, IsCmpt: e.target.checked ? 1 : 0 }))
        setAllData(value.data)
        hasChange = true
      }
    })
  }, [ax, setAllData])

  const handleOpenAddItemTable = useCallback(() => {
    setDialogAddItemTableOpen(true)
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      hasChange = false
      if (selectedId) {
        ax.post(pettyCashApiNm.getPettyCashDetail, { PCTrnsId: selectedId }).then(value => {
          if (value.data) {
            mPCTrnsId = selectedId
            setAllData(value.data)
          }
        })
      }
    } else {
      setPCItemDataTable([])
      setPCTrnsDataTable([])
      setPrPCTrnsData({ ...initAdvanceDialogData })
      if (hasChange) {
        console.log("PettyCashDialog hasChange")
        hasChange = false
        onFinish()
      }
    }
  }, [dialogOpen, ax, selectedId, onFinish, setAllData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="1.7-1 รายละเอียดการใช้เงิน"
        onDeleteClick={canDelete ? handleDeleteTransaction : null}
      />
      <DialogContent>

        <BoxFC pt={2} sx={{ gap: 1 }} height={600}>
          <BoxFR>
            <Typography fontWeight="bold">จ่าย-รับเงิน</Typography>

            <Button variant='contained' size='small' disabled={prPCTrnsData.IsCmpt ? true : false || pcTrnsDataTable.length > 0}
              onClick={handleAddAdvance}>จ่ายเงินล่วงหน้า-รับเงิน</Button>
            <Button variant='contained' size='small' disabled={prPCTrnsData.IsCmpt ? true : false || pcTrnsDataTable.length > 0}
              onClick={handleAddReimbursement}>เบิกค่าใช้จ่าย(มี/ไม่มี ใบเสร็จ)</Button>
            <Button variant="contained" size="small" disabled={prPCTrnsData.IsCmpt ? true : false || pcTrnsDataTable.length === 0}
              onClick={handleAddChange} >เพิ่มเงินทอน-เงินเพิ่ม</Button>
            <Box flex={1} />
            <Typography ><strong>รวมเงิน :</strong>{trnsAmnt === 0 ? "" : trnsAmnt > 0 ? "(จ่าย)" : "(รับ)"} {numberFormat(Math.abs(trnsAmnt))} บาท</Typography>
          </BoxFR>
          <Box flex={1} >
            <DataGridCellExpand
              hideToolbar
              hideFooter
              rows={pcTrnsDataTable}
              columns={pcTransColumns}
              onCellClick={openTrnDialog}
              onRowDoubleClick={openTrnDialog}
            />
          </Box>
          <Divider sx={{ width: "80%", alignSelf: "center" }} />
          <BoxFR pt={1}>
            <Typography fontWeight="bold">รับใบเสร็จ-การใช้เงิน</Typography>
            <ButtonGroup disabled={prPCTrnsData.IsCmpt || pcTrnsDataTable.length === 0 ? true : false}>
              <Button variant="contained" size="small"
                onClick={handleAddPCItem} >เพิ่มการใช้เงิน-รับใบเสร็จ</Button>
              <Button variant="contained" size="small" onClick={handleOpenAddItemTable}>
                <ViewListRounded />
              </Button>
            </ButtonGroup>
            <Button variant="contained" size="small" disabled={prPCTrnsData.IsCmpt
              || pcTrnsDataTable.length !== 1 || pcItemDataTable.length > 0 ? true : false}
              onClick={handleAddItemUseAll} >ใช้พอดี</Button>
            <Box flex={1} />
            <Typography ><strong>รวมเงิน :</strong> {numberFormat(itemAmnt)} บาท</Typography>
          </BoxFR>
          <Box flex={1} sx={{
            "& .cost": { bgcolor: red[50] },
            "& .income": { bgcolor: green[50] },
          }}>
            <DataGridCellExpand
              hideToolbar
              hideFooter
              rows={pcItemDataTable}
              columns={pcItemColumns}
              onCellClick={openItmDialog}
              onRowDoubleClick={openItmDialog}
            />
          </Box>
          <BoxFR>
            <Typography fontWeight="bold">เงินส่วนต่าง </Typography>
            <Typography color={diffAmount === 0 ? "black" : red[800]}>
              {diffAmount === 0 ? "" : diffAmount < 0 ? "(เงินเพิ่ม)" : "(เงินทอน)"}
              {numberFormat(Math.abs(diffAmount))}
            </Typography>
            <Typography> บาท</Typography>
            <ButtonGroup>
              <Button variant="contained" size="small" disabled={prPCTrnsData.IsCmpt ? true : false || diffAmount === 0}
                onClick={handleAutoAddChange(false)} >บันทึกส่วนต่างเป็น{diffAmntText}
              </Button>
              <PopoverBarebone
                ref={cashChangeButtonRef}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                component={
                  <Button size="small" variant="contained" sx={{ minWidth: 0, width: 15, p: 0, height: "100%" }}
                    disabled={prPCTrnsData.IsCmpt ? true : false || diffAmount === 0}>
                    <ArrowDropDownRounded />
                  </Button>
                }
              >
                <Paper sx={{ p: 0.5 }}>
                  <BoxFC sx={{ gap: 1 }}>
                    <Button size="small" variant='outlined' onClick={handleAutoAddChange(true)}>เงินทอนลงเบิก</Button>
                  </BoxFC>
                </Paper>
              </PopoverBarebone>
            </ButtonGroup>
            <ComboBox sx={{ width: 150 }} options={msData.pcJournalCombo} label={`บัญชี${diffAmntText}`}
              disabled={prPCTrnsData.IsCmpt ? true : false || diffAmount === 0}
              selectedId={cashChangePCJnalId}
              setSelectedId={(id) => setCashChangePCJnalId(id)}
            />
            <CheckboxFormControl sx={{ py: 0.5, px: 2, borderRadius: 1, bgcolor: prPCTrnsData.IsCmpt ? green[100] : null }}
              label="รายการเสร็จสิ้นสมบูรณ์" checked={prPCTrnsData.IsCmpt ? true : false}
              onChange={handleIsCmptChange} disabled={diffAmount !== 0} />
          </BoxFR>
        </BoxFC>
        <AddAdvanceDialog
          pcTrnsId={dialogPCTrnsId}
          dialogOpen={dialogAddAdvanceOpen}
          setDialogOpen={setDialogAddAdvanceOpen}
          onFinish={onDialogFinish}
          getFn="getPettyCashDetail" />
        <ReimbursementDialog
          dialogOpen={dialogReimbursementOpen}
          setDialogOpen={setDialogReimbursementOpen}
          onFinish={onDialogFinish}
          getFn="getPettyCashDetail" />
        <ChangeDialog
          initData={initChangeDialogData}
          pcTrnsId={dialogPCTrnsId}
          prPCTrnsId={mPCTrnsId}
          prPCTrnsData={prPCTrnsData}
          dialogOpen={dialogChangeOpen}
          setDialogOpen={setDialogChangeOpen}
          onFinish={onDialogFinish} />
        <AddItemDialog
          maxAmount={Math.abs(diffAmount)}
          pcItmId={pcItmId}
          pcTrnsId={mPCTrnsId}
          prPCTrnsData={prPCTrnsData}
          dialogOpen={dialogItemOpen}
          setDialogOpen={setDialogItemOpen}
          onFinish={onDialogFinish} />
        <AddItemTableDialog
          pcTrnsData={prPCTrnsData}
          dialogOpen={dialogAddItemTableOpen}
          setDialogOpen={setDialogAddItemTableOpen}
          onFinish={onDialogFinish}
        />

      </DialogContent>
      <DialogFooter okText="" cancelText="ปิด"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(PettyCashDialog)