import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobPlaning from "./JobPlaning/JobPlaning"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import JobOrderExpense from "./JobOrderExpense/JobOrderExpense"
import DriverTripPayment from "./DriverTripPayment/DriverTripPayment"
import DriverSalary from "./DriverSalary/DriverSalary"
import DriverAdvancePay from "./DriverAdvancePay/DriverAdvancePay"


// export const branchApiNm = {
//   getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
//   getTruckIncomeSummary: "/branch/getTruckIncomeSummary",
// }

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงาน(V2)",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
  JobOrderExpense: "ค่าเที่ยว-ค่าใช้จ่ายใบงาน",
  DriverTripPayment: "ค่าเที่ยว พนักงานขับรถ",
  DriverSalary: "เงินเดือนพนักงานขับรถ",
  DriverAdvancePay: "เงินเบิกล่วงหน้า",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobOrderAllDetail", num: "8.2", menuName: extraPageName.JobOrderAllDetail, prNum: 8, permission: "EXE;FIN"},
  { page: "JobOrderExpense", num: "8.3", menuName: extraPageName.JobOrderExpense, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverTripPayment", num: "8.4", menuName: extraPageName.DriverTripPayment, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverAdvancePay", num: "8.5", menuName: extraPageName.DriverAdvancePay, prNum: 8},
  { page: "DriverSalary", num: "8.6", menuName: extraPageName.DriverSalary, prNum: 8, permission: "EXE;FIN"},
]

export const extraRoute = (path)=> [
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
  <Route key={"JobOrderallDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"JobOrderExpense"} path={`${path}/JobOrderExpense`} ><JobOrderExpense /> </Route>,
  <Route key={"DriverTripPayment"} path={`${path}/DriverTripPayment`} ><DriverTripPayment /> </Route>,
  <Route key={"DriverAdvancePay"} path={`${path}/DriverAdvancePay`} ><DriverAdvancePay /> </Route>,
  <Route key={"DriverSalary"} path={`${path}/DriverSalary`} ><DriverSalary /> </Route>,
]


export const extraMainRoute = (path)=> []
